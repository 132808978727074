// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../.yarn/__virtual__/css-loader-virtual-c45ab14dec/0/cache/css-loader-npm-6.8.1-30d84b4cf1-f20bb2a181.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../.yarn/__virtual__/css-loader-virtual-c45ab14dec/0/cache/css-loader-npm-6.8.1-30d84b4cf1-f20bb2a181.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.docCard {
    background-color: #22252b;
    margin-right: 32px;
    border: 1px solid rgba(204, 204, 220, 0.12);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative;
    max-height: 230px;
    min-height: 180px;
    min-width: 230px;
}

.docCard::before {
    display: block;
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    top: 0;
    background-image: linear-gradient(to right, #b93d37 0%, #1469b3 100%);
}`, "",{"version":3,"sources":["webpack://./public/app/plugins/panel/gettingstarted/components/DocsCard.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,2CAA2C;IAC3C,8BAA8B;IAC9B,+BAA+B;IAC/B,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,WAAW;IACX,MAAM;IACN,qEAAqE;AACzE","sourcesContent":[".docCard {\n    background-color: #22252b;\n    margin-right: 32px;\n    border: 1px solid rgba(204, 204, 220, 0.12);\n    border-bottom-left-radius: 4px;\n    border-bottom-right-radius: 4px;\n    position: relative;\n    max-height: 230px;\n    min-height: 180px;\n    min-width: 230px;\n}\n\n.docCard::before {\n    display: block;\n    content: ' ';\n    position: absolute;\n    left: 0;\n    right: 0;\n    height: 2px;\n    top: 0;\n    background-image: linear-gradient(to right, #b93d37 0%, #1469b3 100%);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
